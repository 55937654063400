import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Menu,
  MenuItem,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tooltip from "@mui/material/Tooltip";
import "./Header.css";
import {
  notify,
  setIsGenerateTreeDialogOpen,
  setUserDataLoading,
  treeHistoryTitleUpdate,
  setVisualizationType,
} from "../../reducers/treeMapSlice";
import { gotoCustomerPortal, signOut } from "../../services/api-service";
import { ReactComponent as VisualIcon } from "../../assets/images/icons/visualIcon.svg";
import { ReactComponent as DownloadTree } from "../../assets/images/icons/downloadTree.svg";
import { ReactComponent as AsanaIcon } from "../../assets/images/icons/asana.svg";
import { toPng, toSvg, toJpeg } from "html-to-image";
import Logo from "../../assets/images/logo.svg";
import { jsPDF } from "jspdf";
import { IsMobile } from "../../utils/deviceType";
import { getAnalytics, logEvent } from "firebase/analytics";
import AsanaWorkspacesModal from "./AsanaWorkspacesModal";

export default function Header() {
  const dispatch = useDispatch();
  const selectedTreeId = useSelector((state) => state.treeMap.selectedTreeId);
  const userData = useSelector((state) => state.treeMap.userData);
  const treeMap = useSelector((state) => state.treeMap);
  const currentPage = useSelector((state) => state.treeMap.currentPage);
  const visualizationType = useSelector(
    (state) => state.treeMap.visualizationType
  );
  const [titleDisabled, setTitleDisabled] = useState(true);
  const [title, setTitle] = useState(null);
  const [treeLoaded, setTreeLoaded] = useState(false);
  const [visualizeAnchorEl, setVisualizeAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [asanaWorkspaces, setAsanaWorkspaces] = useState(false);
  const open = Boolean(anchorEl);
  const openDownload = Boolean(downloadAnchorEl);
  const openVisualize = Boolean(visualizeAnchorEl);
  const isMobile = IsMobile();
  const analytics = getAnalytics();

  useEffect(() => {
    if (
      selectedTreeId &&
      treeMap.history?.find((el) => el._id === selectedTreeId)
    ) {
      setTitle(treeMap.history.find((el) => el._id === selectedTreeId).title);
    } else if (selectedTreeId === "" && title !== null) {
      setTitle(null);
    }
  }, [treeMap, selectedTreeId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("code")) {
      setAsanaWorkspaces(true);
    }
  }, []);

  const handleTitleFocusChange = () => {
    setTitleDisabled(true);
    (async () => {
      if (title && title !== "") {
        if (
          title ===
          treeMap.history.find((el) => el._id === selectedTreeId).title
        ) {
          setTitleDisabled(true);
          return;
        }
        dispatch(treeHistoryTitleUpdate(selectedTreeId, title));
      } else {
        setTitleDisabled(false);
      }
    })();
  };

  const onAccountClick = async () => {
    dispatch(setUserDataLoading(true));
    const response = await gotoCustomerPortal();
    if (response?.error) {
      notify(response.error.code, "error");
    } else {
      if (response?.url) {
        window.location.href = response?.url;
      }
    }
    setTimeout(() => {
      dispatch(setUserDataLoading(false));
    }, 1000);
  };

  const toggleDialog = () => {
    logEvent(analytics, `Tree Generation Dialog Opened!`, {
      user: userData._id,
    });
    dispatch(setIsGenerateTreeDialogOpen());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const HeaderMenuIcon = ({ label, onClick, ...props }) => (
    <MenuItem sx={{ fontSize: "14px" }} onClick={onClick} {...props}>
      {label}
    </MenuItem>
  );

  const formatToMethodMap = {
    svg: toSvg,
    png: toPng,
    jpg: toJpeg,
  };

  const downloadTree = async (format) => {
    logEvent(analytics, `Tree Download Clicked!`, {
      user: userData._id,
    });

    setDownloadAnchorEl(null);
    const clonedTree = document
      .getElementById(visualizationType === 1 ? "currentList" : "currentTree")
      .cloneNode(true);
    const tempDiv = document.createElement("div");
    tempDiv.style.cssText = "position: relative; background: white";
    const newImg = document.createElement("img");
    newImg.style.cssText = `position: absolute;
    top: ${visualizationType === 2 && "20px"};
    right: ${visualizationType === 1 && "20px"};
    bottom: ${visualizationType === 1 && "40px"};
    left: ${visualizationType === 2 && "20px"};
    width: 25%`;
    tempDiv.appendChild(clonedTree);

    const imgBlob = await fetch(Logo).then((response) => response.blob());
    const imgBlobUrl = URL.createObjectURL(imgBlob);
    newImg.src = imgBlobUrl;

    tempDiv.appendChild(newImg);
    await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      tempDiv.appendChild(newImg);
      document.body.appendChild(tempDiv);
      if (format === "pdf") {
        const pdf = new jsPDF("p", "mm", "a4");
        await toJpeg(tempDiv);
        const imgData = await toJpeg(tempDiv);
        const desiredHeightInMM = pdf.internal.pageSize.height;
        const aspectRatio = tempDiv.clientWidth / tempDiv.clientHeight;
        const desiredWidthInMM = desiredHeightInMM * aspectRatio;
        const xPos = (pdf.internal.pageSize.width - desiredWidthInMM) / 2;
        const yPos = 0;

        pdf.addImage(
          imgData,
          "JPEG",
          xPos,
          yPos,
          desiredWidthInMM,
          desiredHeightInMM
        );
        pdf.save("exported-tree.pdf");
        return;
      } else if (format === "txt") {
        let text = "";
        let sectionCounter = 1;

        treeMap.data?.children?.forEach((section) => {
          text += `${sectionCounter}- ${section.name}\n`;

          section.children.forEach((subsection, subIndex) => {
            const subsectionLetter = String.fromCharCode(65 + subIndex);
            text += `${subsectionLetter}- ${subsection.name}\n`;

            subsection.children.forEach((item) => {
              text += `-${item.name}\n`;
            });
          });
          sectionCounter++;
        });

        const element = document.createElement("a");
        const file = new Blob([text], {
          type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = "exported-tree.txt";
        document.body.appendChild(element);
        element.click();
        return;
      }
      await formatToMethodMap[format](tempDiv);
      const dataUrl = await formatToMethodMap[format](tempDiv);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `exported-tree.${format}`;
      link.click();
    } catch (error) {
      console.error(`Error exporting ${format}:`, error);
    } finally {
      URL.revokeObjectURL(imgBlobUrl);
      document.body.removeChild(tempDiv);
    }
  };

  useEffect(() => {
    if (treeLoaded) {
      setTreeLoaded(treeMap.data && !treeMap.loading && !treeMap.isTreeLoading);
    } else {
      const timeoutId = setTimeout(() => {
        setTreeLoaded(
          treeMap.data && !treeMap.loading && !treeMap.isTreeLoading
        );
      }, 4000);
      return () => clearTimeout(timeoutId);
    }
  }, [treeMap.data, treeMap.loading, treeMap.isTreeLoading]);

  return (
    <div className="header-container">
      <div>
        <Button
          className="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={
            <div className="username">
              {userData?.email ? userData?.email.charAt(0) : null}
            </div>
          }
        ></Button>
        <Menu
          className="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={0}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              minWidth: "100px",
              maxWidth: "100px",
              borderRadius: "3px",
              margin: "2px 0px 2px",
              border: "1px solid #dde0e4",
              boxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
              WebkitBoxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <HeaderMenuIcon
            label="Privacy"
            onClick={() =>
              window.open("https://www.antethink.com/privacy-policy", "_blank")
            }
          />
          <HeaderMenuIcon label="Account" onClick={() => onAccountClick()} />
          <HeaderMenuIcon label="Sign-out" onClick={() => signOut()} />
        </Menu>
      </div>
      {/* Load title of the loaded d3 chart */}
      <Box style={{ display: "flex", alignItems: "center" }}>
        {!titleDisabled ? (
          <TextField
            disabled={titleDisabled}
            inputProps={{ style: { fontFamily: "ClashDisplay-regular" } }}
            className="tree-title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={() => handleTitleFocusChange()}
            autoFocus={true}
          />
        ) : (
          <Tooltip title="Edit Title" arrow>
            <Box
              className="tree-title"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              onClick={() => setTitleDisabled(false)}
            >
              {title}
            </Box>
          </Tooltip>
        )}
        <div style={{ display: "flex" }}>
          <Tooltip title="Export event to Asana project" arrow>
            <IconButton
              className="exportToAsana"
              onClick={() => {
                let url = new URL(window.location.href).searchParams,
                  stateObject = JSON.stringify({
                    eventId: selectedTreeId,
                    page: `${currentPage}`,
                  });
                window.open(
                  `https://app.asana.com/-/oauth_authorize?client_id=1207806128873782&redirect_uri=${window.location.origin}/&response_type=code&scope=default&state=${stateObject}`,
                  "_self"
                );
              }}
              sx={{
                padding: "0px",
                ml: "5px",
                visibility: treeLoaded ? "visible" : "hidden",
                opacity: treeLoaded ? 1 : 0,
                backgroundColor: "transparent",
              }}
            >
              <AsanaIcon style={{ marginRight: -3 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Tree" arrow>
            <IconButton
              className="downloadTree"
              onClick={(event) => setDownloadAnchorEl(event.currentTarget)}
              sx={{
                padding: "0px",
                minWidth: "5px",
                margin: "0px 8px",
                visibility: treeLoaded ? "visible" : "hidden",
                opacity: treeLoaded ? 1 : 0,
                backgroundColor: "transparent",
              }}
            >
              <DownloadTree />
            </IconButton>
          </Tooltip>
          <Menu
            className="user-menu"
            anchorEl={downloadAnchorEl}
            open={openDownload}
            onClose={() => setDownloadAnchorEl(null)}
            elevation={0}
            MenuListProps={{
              "aria-labelledby": "basic-button",
              sx: {
                minWidth: "100px",
                maxWidth: "100px",
                borderRadius: "3px",
                margin: "2px 0px 2px",
                border: "1px solid #dde0e4",
                boxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
                WebkitBoxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <HeaderMenuIcon
              label="Save as SVG"
              onClick={() => downloadTree("svg")}
              style={{ fontSize: "12px" }}
            />
            <HeaderMenuIcon
              label="Save as PNG"
              onClick={() => downloadTree("png")}
              style={{ fontSize: "12px" }}
            />
            <HeaderMenuIcon
              label="Save as JPG"
              onClick={() => downloadTree("jpg")}
              style={{ fontSize: "12px" }}
            />
            <HeaderMenuIcon
              label="Save as PDF"
              onClick={() => downloadTree("pdf")}
              style={{ fontSize: "12px" }}
            />
            <HeaderMenuIcon
              label="Save as TXT"
              onClick={() => downloadTree("txt")}
              style={{ fontSize: "12px" }}
            />
          </Menu>
          <Tooltip title="Change Visualization" arrow>
            <IconButton
              className="visualizeType"
              onClick={(event) => setVisualizeAnchorEl(event.currentTarget)}
              sx={{
                padding: "0px",
                marginLeft: "-2px",
                visibility: treeLoaded ? "visible" : "hidden",
                opacity: treeLoaded ? 1 : 0,
                backgroundColor: "transparent",
              }}
            >
              <VisualIcon />
            </IconButton>
          </Tooltip>
          <Menu
            className="user-menu"
            anchorEl={visualizeAnchorEl}
            open={openVisualize}
            onClose={() => setVisualizeAnchorEl(null)}
            elevation={0}
            MenuListProps={{
              "aria-labelledby": "basic-button",
              sx: {
                minWidth: "120px",
                maxWidth: "120px",
                borderRadius: "3px",
                margin: "2px 0px 2px",
                border: "1px solid #dde0e4",
                boxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
                WebkitBoxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <HeaderMenuIcon
              label="Visualize as Tree"
              onClick={() =>
                dispatch(setVisualizationType(2), setVisualizeAnchorEl(null))
              }
              style={{ fontSize: "12px" }}
            />
            <HeaderMenuIcon
              label="Visualize as List"
              onClick={() =>
                dispatch(setVisualizationType(1), setVisualizeAnchorEl(null))
              }
              style={{ fontSize: "12px" }}
            />
          </Menu>
        </div>
      </Box>
      <Box className="add-tree" onClick={toggleDialog}>
        <AddCircleIcon color="primary"></AddCircleIcon>
        {!isMobile && <Box className="new-title">New Tree</Box>}
      </Box>
      {asanaWorkspaces && (
        <AsanaWorkspacesModal
          asanaWorkspaces={asanaWorkspaces}
          setAsanaWorkspaces={setAsanaWorkspaces}
        />
      )}
    </div>
  );
}
