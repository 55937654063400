import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddchartIcon from "@mui/icons-material/Addchart";
import { setIsGenerateTreeDialogOpen } from "../../reducers/treeMapSlice";
import DecisionTreeVisualization from "../DecisionTreeVisualization/DecisionTreeVisualization";
import Loader from "../Loader/Loader";
import IndentedList from "../IndentedList/IndentedList";

export default function Layout() {
  const treeData = useSelector((state) => state.treeMap.data);
  const treeLoading = useSelector((state) => state.treeMap.isTreeLoading);
  const treeHistoryLoading = useSelector(
    (state) => state.treeMap.isTreeHistoryLoading
  );
  const treeId = useSelector((state) => state.treeMap.selectedTreeId);
  const loading = useSelector((state) => state.treeMap.loading);
  const visualizationType = useSelector(
    (state) => state.treeMap.visualizationType
  );
  const dispatch = useDispatch();

  return (
    <Box style={{ height: "100%", position: "relative" }}>
      {(treeHistoryLoading && !treeId) ||
      treeLoading ||
      loading ||
      !treeData ? (
        <Loader
          treeGeneratingLoader
          type={treeLoading ? "treeCreating" : "treeLoading"}
        />
      ) : treeData ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          {visualizationType === 2 ? (
            <DecisionTreeVisualization
              treeData={treeData}
              treeLoading={treeLoading}
            />
          ) : (
            <IndentedList data={treeData?.Pitfalls ?? treeData?.children} />
          )}
        </Box>
      ) : (
        <Box className="create-chart-button-container">
          <Tooltip title="Generate new Tree">
            <IconButton
              onClick={() => dispatch(setIsGenerateTreeDialogOpen())}
              color="primary"
            >
              <AddchartIcon
                className="add-chart-icon"
                fontSize="large"
                color="primary"
              />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
