import axios from "axios";
import store from "../reducers/store";
import { reset } from "../reducers/treeMapSlice";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL_DEV}/v1`;
let generatePlanCancelAPI = null;

const commonHeaders = () => ({ Authorization: `Bearer ${localStorage.getItem("accessToken")}`, "Content-Type": "application/json", });

const handleError = (error) => {
  if (error?.response?.status === 401) {
    signOut()
  }
}

export const generateStripeURL = async (token) => {
  return await axios.post(`${apiUrl}/stripe/checkout/sessions`, {
    headers: commonHeaders(),
  })
}

export const signOut = async () => {
  store.dispatch(reset());
  localStorage.removeItem("accessToken");
  window.location.href = "/signin";
}

export const sendPasswordResetLink = async ({ email }) => {
  try {
    const res = await axios.post(`${apiUrl}/auth/forget-password`, { email })
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const resetPassword = async ({ password, comfirm_password }) => {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token")

    const res = await axios.post(`${apiUrl}/auth/reset-password?token=${token}`, { password, comfirm_password })
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const getUserData = async (userIdToken) => {
  try {
    const res = await axios.get(`${apiUrl}/users/profile`, {
      headers: {
        Authorization: `Bearer ${userIdToken}`,
      },
    })
    return res?.data;
  } catch (error) {
    handleError(error);
  }
}

export const createTree = async ({ event, context, title, requestType }) => {
  try {
    const res = await axios.post(`${apiUrl}/trees`, { event, context, title, requestType }, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const editTree = async ({ key, title, event, context, requestType }) => {
  try {
    const res = await axios.put(
      `${apiUrl}/trees/${key}`, { title, event, context, requestType }, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const getTree = async (key) => {
  try {
    const res = await axios.get(`${apiUrl}/trees/${key}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const getTreeHistory = async ({ currentPage }) => {
  try {
    const res = await axios.get(`${apiUrl}/trees?page=${currentPage}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const removeTreeHistoryRecord = async ({ deleteTreeId }) => {
  try {
    const res = await axios.delete(`${apiUrl}/trees/${deleteTreeId}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const udpateTreeHistoryTitle = async ({ treeHistoryId, title }) => {
  try {
    const res = await axios.put(`${apiUrl}/trees/${treeHistoryId}`, { title }, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const generatePlan = async ({ key, node_name }) => {
  try {
    const res = await axios.post(`${apiUrl}/trees/${key}/plans`, { node_name }, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
    return error;
  }
}

export const getPlan = async ({ key, nodeName }) => {
  try {
    const res = await axios.get(`${apiUrl}/trees/${key}/plans?node_name=${nodeName}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const removePlanRecord = async ({ key, node_name }) => {
  try {
    const res = await axios.delete(`${apiUrl}/trees/${key}/plans/?node_name=${node_name}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const updatePlan = async ({ key, node_name, context }) => {
  try {
    const res = await axios.patch(`${apiUrl}/trees/${key}/plans`, { node_name, context }, {
      headers: commonHeaders(),
    })
    return res;
  }
  catch (error) {
    handleError(error);
  }
}

export const gotoCustomerPortal = async () => {
  try {
    const res = await axios.post(`${apiUrl}/stripe/billing_portal`, {}, {
      headers: commonHeaders(),
    })
    return res.data;
  } catch (error) {
    handleError(error);
  }
}

export const getAsanaWorkspacesById = async ({ eventID, asanaId, redirectUrl }) => {
  try {
    const res = await axios.get(`${apiUrl}/trees/${eventID}/asana/workspaces?code=${asanaId}&tokenUrl=${redirectUrl}`, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const exportEventToAsana = async ({ eventID, workspaceId, teamId }) => {
  try {
    const res = await axios.post(`${apiUrl}/trees/${eventID}/asana/export`, { workspaceId, teamId }, {
      headers: commonHeaders(),
    })
    return res;
  } catch (error) {
    console.log(error);
  }
}