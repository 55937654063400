import React, { useEffect, useState, forwardRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  Slide,
  DialogActions,
  Button,
} from "@mui/material";
import { getAsanaWorkspacesById } from "../../services/api-service";
import { useSearchParams } from "react-router-dom";
import { AsanaWorkspace } from "./AsanaWorkspace";
import Loader from "../Loader/Loader";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AsanaWorkspacesModal({
  asanaWorkspaces,
  setAsanaWorkspaces,
}) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [workspaces, setWorkspaces] = useState([]);
  const [teamClicked, setTeamClicked] = useState(false);

  const toggleDialog = () => {
    searchParams.delete("code");
    searchParams.delete("state");
    setSearchParams(searchParams);
    setAsanaWorkspaces(!asanaWorkspaces);
    window.history.replaceState(null, "", window.location.href);
  };

  useEffect(() => {
    const asanaId = searchParams?.get("code"),
      eventID = JSON.parse(searchParams?.get("state")).eventId;
    if (eventID && asanaId) {
      getAsanaWorkspacesById({
        eventID,
        asanaId,
        redirectUrl: `${window.location.origin}/`,
      }).then((res) => {
        setWorkspaces(res?.data?.workspease);
      });
    }
  }, [searchParams]);

  return (
    <Dialog
      open={asanaWorkspaces}
      TransitionComponent={Transition}
      onMouseDown={(e) => e.stopPropagation()}
      onClose={toggleDialog}
      sx={{
        textAlign: "center",
        backdropFilter: "blur(4px)",
        "& .MuiPaper-root": {
          borderRadius: "20px",
          padding: "0px 30px",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: "ClashDisplay-medium",
          padding: "24px 20px 0px",
          fontSize: "1.5rem",
        }}
      >
        {teamClicked
          ? "Your tree is being exported to Asana"
          : "Choose Your Team"}
      </DialogTitle>
      <DialogContent sx={{ minHeight: "5vh", maxHeight: "60vh", mt: "1vh" }}>
        {teamClicked ? (
          "You will receive an email as soon as the transfer is complete!"
        ) : workspaces?.length > 0 ? (
          <List>
            {workspaces.map((workspace) => (
              <AsanaWorkspace
                workspace={workspace}
                setTeamClicked={setTeamClicked}
              />
            ))}
          </List>
        ) : (
          <Loader
            text="Loading..."
            extraStyles={{ marginTop: 3, marginBottom: 4 }}
          />
        )}
      </DialogContent>
      {teamClicked && (
        <DialogActions sx={{ marginTop: -3, marginBottom: 1 }}>
          <Button onClick={toggleDialog} sx={{ borderRadius: 2 }}>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
