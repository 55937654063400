import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { useAuth } from "../services/auth";
import { gotoCustomerPortal, getUserData } from "../services/api-service";
import { notify } from "../reducers/treeMapSlice";
import { ReactComponent as BrandLogo } from "../assets/images/logo.svg";

const Subscribe = () => {
  const { user, token } = useAuth();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({
    email: "",
    id: "",
    hadFreeTrial: false,
  });
  const [userLoading, setUserLoading] = useState(false);

  const GetUserData = async () => {
    if (token) {
      setUserLoading(true);
      getUserData(token)
        .then(async (res) => {
          if (
            res?.user?.ante_Application?.subscriptions.length &&
            res.user.ante_Application.subscriptions[0].active
          ) {
            const response = await gotoCustomerPortal();
            if (response?.error) {
              notify(response.error.code, "error");
            } else {
              if (response?.url) {
                window.location.href = response?.url;
                if (window.location.href == response?.url) {
                  setUserLoading(false);
                }
              }
            }
          } else {
            setCustomer({
              email: res.user.email,
              id: res.user._id,
              hadFreeTrial: res.user.hasOwnProperty("hadFreeTrial"),
            });
            setTimeout(() => {
              setUserLoading(false);
            }, 1500);
          }
        })
        .catch(() => navigate("/signin"));
    } else navigate("/signin");
  };

  useEffect(() => {
    if (!userLoading) {
      GetUserData();
    }
  }, [user]);

  return (
    <Box>
      {userLoading ? (
        <Loader />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", mt: "5vh" }}>
          <BrandLogo
            onClick={() => navigate("/signin")}
            style={{ alignSelf: "center", cursor: "pointer" }}
          />
          <Box sx={{ mt: "50px" }}>
            <stripe-pricing-table
              class="stripe-pricing-table"
              pricing-table-id={
                customer.hadFreeTrial
                  ? process.env.REACT_APP_STRIPE_PRICING_TABLE_ID_NO_TRIAL
                  : process.env.REACT_APP_STRIPE_PRICING_TABLE_ID
              }
              publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
              client-reference-id={customer.id}
              customer-email={customer.email}
            ></stripe-pricing-table>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Subscribe;
