import { createSlice } from "@reduxjs/toolkit";
import * as apiService from "../services/api-service";
import { toast } from "react-toastify";
import { getSocket } from "../socket";

const initialState = {
  data: null,
  treeFullData: null,
  history: [],
  planData: null,
  isGenerateTreeDialogOpen: false,
  isDeleteTreeconfirmModalOpen: false,
  userDataLoading: false,
  userData: null,
  selectedTreeId: "",
  selectedDeleteTreeId: "",
  selectedDeleteTreeName: "",
  selectedUpdateTreeId: "",
  loading: false,
  plansLoading: false,
  planGenerating: false,
  isTreeHistoryLoading: false,
  isTreeLoading: false,
  treeGenerating: false,
  visibleRightPanel: "TreeHistory",
  visualizationType: 2,
  totalPages: 1,
  currentPage: 1,
  selectedTreeNode: null,
  deleteConfirmationModalType: "",
  selectedTreePlannedNodeNames: [],
};

const isMobile = window.innerWidth < window.innerHeight
const socket = (userDataId) => getSocket(userDataId);
let planResponseData = "", treeResponseData = "";

// Slice
const treeMapSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setTreeFullData: (state, action) => {
      state.treeFullData = action.payload;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setHistoryLoading: (state, action) => {
      state.isTreeHistoryLoading = action.payload;
    },
    setPlanData: (state, action) => {
      state.planData = action.payload;
    },
    setIsGenerateTreeDialogOpen: (state, action) => {
      state.isGenerateTreeDialogOpen = !state.isGenerateTreeDialogOpen;
      state.selectedUpdateTreeId = action.payload?.id;
    },
    setIsDeleteTreeconfirmModalOpen: (state, action) => {
      state.isDeleteTreeconfirmModalOpen = !state.isDeleteTreeconfirmModalOpen;
      state.deleteConfirmationModalType = action.payload?.type;
      state.selectedDeleteTreeId = action.payload?.id;
      state.selectedDeleteTreeName = action.payload?.name;
    },
    setSelectedTreeId: (state, action) => {
      state.selectedTreeId = action.payload;
    },
    setVisibleRightPanel: (state, action) => {
      state.visibleRightPanel = action.payload;
    },
    setPlanLoading: (state, action) => {
      state.plansLoading = action.payload;
    },
    setPlanGenerating: (state, action) => {
      state.planGenerating = action.payload;
    },
    setUserDataLoading: (state, action) => {
      state.userDataLoading = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setSelectedTreePlannedNodeNames: (state, action) => {
      state.selectedTreePlannedNodeNames = action.payload;
    },
    setSelectedTreeNode: (state, action) => {
      state.selectedTreeNode = action.payload;
    },
    setVisualizationType: (state, action) => {
      state.visualizationType = action.payload;
    },
    setTreeLoading: (state, action) => {
      state.isTreeLoading = action.payload;
    },
    setTreeGenerating: (state, action) => {
      state.treeGenerating = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    }
  },
});

// Actions
export const {
  reset,
  setData,
  setTreeFullData,
  setHistory,
  setIsGenerateTreeDialogOpen,
  setIsDeleteTreeconfirmModalOpen,
  setSelectedTreeId,
  setTreeLoading,
  setTreeGenerating,
  setHistoryLoading,
  setTreeHistoryLoading,
  setVisibleRightPanel,
  setPlanLoading,
  setPlanGenerating,
  setPlanData,
  setVisualizationType,
  setUserDataLoading,
  setUserData,
  setSelectedTreePlannedNodeNames,
  setSelectedTreeNode,
  setLoading,
  setTotalPages,
  setCurrentPage,
} = treeMapSlice.actions;
export default treeMapSlice.reducer;

const parsedResponse = (res) => {
  const validateJSON = (text) => {
    try {
      return JSON.parse(text);
    } catch (error) {
      return;
    }
  };
  let stack = [];
  const brackets = {
    '"': '"',
    "[": "]",
    "{": "}",
  };
  for (const char of res) {
    let lastBracket = stack.slice(-1);
    if (brackets[char]) {
      if (char == '"' && lastBracket == '"') {
        stack.pop();
      } else stack.push(brackets[char]);
    } else {
      if (char == lastBracket) {
        stack.pop();
      }
    }
  }
  return validateJSON(`${res} ${stack.reverse().join("")}`);
};

const onConnect_GenerateTree = (title, event, context, requestType, dispatch, key) => {
  dispatch(setVisualizationType(1))
  if (key) {
    dispatch(editTree(title, event, context, requestType, key));
  } else {
    dispatch(createTree(title, event, context, requestType));
  }
};

const onStreamStarted_GenerateTree = (res, title, event, context, requestType, dispatch, historyData, key) => {
  const newEvent = {
    _id: res.treeId,
    title,
    context,
    requestType,
    name: event,
  };
  const updatedHistory = key ? historyData.map(el => el._id === key ? { ...el, ...newEvent } : el) : [newEvent, ...historyData];
  dispatch(setHistory(updatedHistory));
  dispatch(setSelectedTreeId(res.treeId));
  dispatch(setVisibleRightPanel("TreeHistory"));
  treeResponseData = "";
  dispatch(setTreeGenerating(true));
  dispatch(setTreeLoading(false));
};

const onReciveStreamChunk_GenerateTree = (chunk, dispatch) => {
  treeResponseData += chunk;
  parsedResponse(treeResponseData) && dispatch(setData(parsedResponse(treeResponseData)));
};

const onStreamCompleted_GenerateTree = (dispatch, socket) => {
  dispatch(setTreeGenerating(false));
  socket.off("connect");
  socket.off("streamStarted");
  socket.off("streamChunk");
  socket.off("streamCompleted");
  socket.disconnect();
  treeResponseData = "";
};

const onConnect_GeneratePlan = (node_name, dispatch, key, context) => {
  if (key) {
    dispatch(updatePlan(key, node_name, context));
  } else {
    dispatch(generatePlan(node_name));
  }
};

const onStreamStarted_GeneratePlan = (node_name, dispatch, selectedTreePlannedNodeNames) => {
  planResponseData = "";
  dispatch(setPlanGenerating(true));
  dispatch(
    setSelectedTreePlannedNodeNames([
      ...selectedTreePlannedNodeNames,
      node_name,
    ])
  );
  dispatch(setPlanLoading(false));
};

const onReciveStreamChunk_GeneratePlan = (chunk, dispatch) => {
  planResponseData += chunk;
  dispatch(setPlanData(planResponseData));
};

const onStreamCompleted_GeneratePlan = (dispatch, socket) => {
  dispatch(setPlanGenerating(false));
  socket.off("connect");
  socket.off("streamStarted");
  socket.off("streamChunk");
  socket.off("streamCompleted");
  socket.disconnect();
  planResponseData = "";
};

export const managePlanGeneration = (node_name, key, context) => async (dispatch, getState) => {
  const userDataId = getState().treeMap.userData?._id;
  const planLoading = getState().treeMap.planLoading;
  const planGenerating = getState().treeMap.planGenerating;
  const treeLoading = getState().treeMap.isTreeLoading;
  const treeGenerating = getState().treeMap.treeGenerating;
  const selectedTreePlannedNodeNames = getState().treeMap.selectedTreePlannedNodeNames;

  if (planLoading || planGenerating || treeLoading || treeGenerating) return;
  dispatch(setPlanLoading(true));
  dispatch(setVisibleRightPanel("Plans"));
  dispatch(setSelectedTreeNode(node_name));
  if (selectedTreePlannedNodeNames.includes(node_name) && !key) {
    dispatch(getPlan(node_name));
  } else {
    dispatch(setPlanData(""));
    socket(userDataId).on("connect", () => onConnect_GeneratePlan(node_name, dispatch, key, context));
    socket(userDataId).on("streamStarted", () => onStreamStarted_GeneratePlan(node_name, dispatch, selectedTreePlannedNodeNames));
    socket(userDataId).on("streamChunk", (chunk) => onReciveStreamChunk_GeneratePlan(chunk, dispatch));
    socket(userDataId).on("streamCompleted", () => onStreamCompleted_GeneratePlan(dispatch, socket(userDataId)));
    socket(userDataId).connect();
  }
}

export const manageTreeGeneration = (title, event, context, requestType, key) => async (dispatch, getState) => {
  const userDataId = getState().treeMap.userData?._id;
  const treeLoading = getState().treeMap.isTreeLoading;
  const treeGenerating = getState().treeMap.planGenerating;
  const historyData = getState().treeMap.history;

  if (treeLoading || treeGenerating) return;
  dispatch(setTreeLoading(true));

  dispatch(setData(""));
  socket(userDataId).on("connect", () => onConnect_GenerateTree(title, event, context, requestType, dispatch, key));
  socket(userDataId).on("streamStarted", (res) => onStreamStarted_GenerateTree(res, title, event, context, requestType, dispatch, historyData, key));
  socket(userDataId).on("streamChunk", (chunk) => onReciveStreamChunk_GenerateTree(chunk, dispatch));
  socket(userDataId).on("streamCompleted", () => onStreamCompleted_GenerateTree(dispatch, socket(userDataId)));
  socket(userDataId).connect();
}

export const createTree = (title, event, context, requestType) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setTreeLoading(true));
    apiService.createTree({
      event,
      context,
      title,
      requestType,
    })
      .catch((error) => {
        dispatch(setData(null));
        if (error?.response?.data?.code === 'ECONNRESET') {
          notify("Network error: Please check your internet connection.", "error");
        } else {
          console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        }
        reject(error);
      })
      .finally(() => {
        // dispatch(setData(getState().treeMap.data));
        dispatch(setTreeLoading(false));
      });
  });
};

export const editTree = (title, event, context, requestType, key) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(setSelectedTreeId(key));
    dispatch(setTreeLoading(true));
    apiService.editTree({
      key,
      title,
      event,
      context,
      requestType,
    })
      .catch((error) => {
        dispatch(setData(null));
        if (error?.response?.data?.code === 'ECONNRESET') {
          notify("Network error: Please check your internet connection.", "error");
        } else {
          console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setData(getState().treeMap.data));
        dispatch(setTreeLoading(false));
      });
  });
};

export const getTree = (key) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const historyData = getState().treeMap.history;
    if (historyData && getState().treeMap.selectedTreeId !== key) {
      dispatch(setLoading(true));
      dispatch(setSelectedTreeId(key));
      apiService.getTree(key)
        .then((response) => {
          if (response?.error) {
            notify(response.error.code, "error");
            dispatch(setTreeFullData(null));
            // reject();
          } else if (response.status === 200) {
            dispatch(setSelectedTreePlannedNodeNames(response.data?.tree?.plans?.map((el) => el.node_name)));
            dispatch(setData(response.data?.tree?.node));
            dispatch(setTreeFullData(response.data?.tree));

          }
          resolve();
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setTreeFullData(null));
          dispatch(setLoading(false));
          // console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        })
    }
  });
};

export const getTreeHistory = (currentPage) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setHistoryLoading(true));
    apiService.getTreeHistory({
      currentPage
    })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
          dispatch(setHistory(null));
        } else if (response?.status === 200) {
          dispatch(setTotalPages(response.data?.paginate.totalPages))
          dispatch(setHistory(response.data?.trees));
          dispatch(setHistoryLoading(false));
        }
        resolve(response);
      }).catch(error => {
        console.log("ERROR MESSAGE", error);
        notify(error.message, "error");
      })
  })
};

export const treeHistoryRemove =
  () => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      let deleteTreeId = state.treeMap.selectedDeleteTreeId;
      apiService.removeTreeHistoryRecord({ deleteTreeId })
        .then((response) => {
          if (response?.error) {
            notify(response.error.code, "error");
          } else if (response.status === 200) {
            let updatedHistory = state.treeMap.history.filter((el) => el._id !== deleteTreeId)
            if (updatedHistory?.length >= 0) {
              dispatch(setData(null));
            }
            dispatch(setHistory(updatedHistory));
            dispatch(
              setIsDeleteTreeconfirmModalOpen({ type: "tree", id: "", name: "" })
            );
            dispatch(setSelectedTreeId(""));
            notify("Tree Removed Successfully!", "success")
          }
          resolve();
        })
        .catch((error) => {
          notify(error.message, "error");
        })
    })
  };

export const treeHistoryTitleUpdate =
  (treeHistoryId, title) => async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      apiService
        .udpateTreeHistoryTitle({
          treeHistoryId,
          title,
        })
        .then((response) => {
          if (response?.error) {
            notify(response.error.code, "error");
          } else if (response.status === 200) {
            let updatedHistory = state.treeMap.history.map((el) => el._id === treeHistoryId ? { ...el, title } : el)
            dispatch(setHistory(updatedHistory));
          }
          resolve();
        })
        .catch((error) => {
          notify(error.message, "error");
        });
    });
  };

export const generatePlan = (node_name) => async (dispatch, getState) => {
  const selectedTreeId = getState().treeMap.selectedTreeId;
  try {
    await apiService.generatePlan({
      key: selectedTreeId,
      node_name,
    }).then((res) => {
      if (res?.status !== 201) {
        dispatch(setPlanGenerating(false));
        dispatch(setPlanData(null));
        dispatch(setPlanLoading(false));
        notify(res?.response?.data?.message, "error");
      }
    });
  } catch (error) {
    dispatch(setPlanData(null));
    dispatch(setPlanLoading(false));
    dispatch(setPlanGenerating(false));
    console.error("Error generating plan:", error);
  }
}

export const getPlan = (node_name) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedTreeId = getState().treeMap.selectedTreeId;

    apiService.getPlan({
      key: selectedTreeId,
      nodeName: node_name
    })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
        } else if (response?.status === 200) {
          dispatch(setPlanData(response.data?.result));
          dispatch(setPlanLoading(false));
        }
        resolve(response);
      }).catch(error => {
        console.log("ERROR MESSAGE", error);
        notify(error.message, "error");
      })
  })
}

export const updatePlan = (key, node_name, context) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    apiService.updatePlan({
      key,
      node_name,
      context
    })
      .catch(error => {
        dispatch(setPlanData(null));
        console.error("Error updating plan:", error);
      })
  })
};

export const removePlan = (key, node_name) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    apiService.removePlanRecord({
      key,
      node_name,
    }).then((response) => {
      if (response?.error) {
        notify(response.error.code, "error");
      } else if (response.status === 200) {
        notify("Plan Removed Successfully!", "success");
        dispatch(setPlanData(null));
        dispatch(
          setSelectedTreePlannedNodeNames(
            state.treeMap.selectedTreePlannedNodeNames.filter(
              (elm) => elm !== node_name
            )
          )
        );
        dispatch(setIsDeleteTreeconfirmModalOpen({ type: "plan" }));
        document.getElementById(`sparkle${node_name}`)?.remove();
        dispatch(setSelectedTreeNode(null));
        dispatch(setVisibleRightPanel("TreeHistory"));
      }
      resolve();
    })
      .catch((error) => {
        notify(error.message, "error");
      })
  })
};

export const notify = (title, type) => {
  let color = "#3a9c3b";
  if (type === "error") {
    color = "#d73f35";
  } else if (type === "info") {
    color = "#3498DB"
  }
  return toast(title, {
    position: "top-right",
    autoClose: type === "success" ? 1000 : 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    type,
    style: {
      background: color,
      fontFamily: "ClashDisplay-Medium",
      width: isMobile && "80vw",
      margin: isMobile ? "4vw" : 0,
      borderRadius: "4px",
    },
    theme: "colored",
  });
};
