import React, { useState, useRef, useEffect } from "react";
import { managePlanGeneration } from "../../reducers/treeMapSlice";
import { useSelector, useDispatch } from "react-redux";
import "./IndentedList.css";
import { isSafariBrowser } from "../../utils/constants";
import { getAnalytics, logEvent } from "firebase/analytics";

export const ChildlessRow = ({ rowData, rowCollapsed }) => {
  const rowRef = useRef(null);
  const [rowHovered, setRowHovered] = useState(false);
  const [rowHeight, setRowHeight] = useState();
  const selectedTreePlannedNodeNames = useSelector(
    (state) => state.treeMap.selectedTreePlannedNodeNames
  );
  const planGenerating = useSelector((state) => state.treeMap.planGenerating);
  const planLoading = useSelector((state) => state.treeMap.planLoading);
  const userData = useSelector((state) => state.treeMap.userData);
  const selectedTreeNode = useSelector(
    (state) => state.treeMap.selectedTreeNode
  );
  const plannedRow = selectedTreePlannedNodeNames?.includes(rowData.name);
  const analytics = getAnalytics();
  const dispatch = useDispatch();

  const Spakles = (isPlan, selected) => (
    <svg
      width={isPlan ? "12" : "14"}
      height={isPlan ? "12" : "14"}
      viewBox="0 0 19 18"
      fill={selected ? "#1D77B7" : "none"}
      style={{
        marginLeft: 4,
        marginTop: 3,
        transition: "opacity 1.25s ease-in-out",
      }}
    >
      <path
        d="M11.7671 0.680054L13.3533 4.96676L17.6401 6.55299L13.3533 8.13921L11.7671 12.4259L10.1809 8.13921L5.89414 6.55299L10.1809 4.96676L11.7671 0.680054Z"
        stroke={selected ? "#1D77B7" : "#585858"}
        strokeWidth={1.3}
        strokeLinejoin="round"
      />
      <path
        d="M4.4259 10.4683L5.81155 12.5085L7.85179 13.8942L5.81155 15.2798L4.4259 17.32L3.04024 15.2798L1 13.8942L3.04024 12.5085L4.4259 10.4683Z"
        stroke={selected ? "#1D77B7" : "#585858"}
        strokeWidth={1.3}
        strokeLinejoin="round"
      />
    </svg>
  );

  const handleNodeClick = (nodeData) => {
    logEvent(analytics, `Plan it Clicked!`, {
      user: userData._id,
    });
    dispatch(managePlanGeneration(nodeData.name));
  };

  useEffect(() => {
    setRowHeight(
      document.getElementById(rowData.name)?.getBoundingClientRect().height
    );
  }, []);

  return (
    <div
      className={`childlessRow ${rowCollapsed ? "collapsed" : "expanded"}`}
      ref={rowRef}
      style={{
        width: "calc(100% - 60px)",
        height: rowCollapsed ? "0px" : rowHeight ?? "auto",
        opacity: 1,
      }}
      onMouseEnter={() => setRowHovered(true)}
      onMouseLeave={() => setRowHovered(false)}
      key={rowData.name}
      id={rowData.name}
    >
      <span
        onClick={() =>
          planGenerating || planLoading ? null : handleNodeClick(rowData)
        }
        style={{
          paddingTop: isSafariBrowser ? 2.5 : 1,
          paddingRight: 5,
          paddingBottom: 2.5,
          cursor: "pointer",
          maxWidth: `calc(100% - ${plannedRow ? "230px" : "270px"})`,
        }}
      >
        {rowData.name}
      </span>
      {(plannedRow || rowHovered) && (
        <div style={{ display: "flex" }}>
          <span
            className={plannedRow ? "" : "plan"}
            style={{ cursor: "pointer", marginTop: "0.2px" }}
            onClick={(e) => (e.stopPropagation(), handleNodeClick(rowData))}
          >
            {!plannedRow && "Plan"}
            {plannedRow
              ? Spakles("", selectedTreeNode === rowData.name)
              : Spakles("plan", false)}
          </span>
        </div>
      )}
    </div>
  );
};
