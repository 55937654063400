import React, { useEffect, useState } from "react";
import "./IndentedList.css";
import { ChildlessRow } from "./ChildlessRow";
import { CollapseIcon } from "./CollapseIcon";
import { Box } from "@mui/material";

export const TableRow = ({
  rowData,
  firstChild,
  collapseAll,
  setCollapseAll,
  childCollapsed,
}) => {
  const [childlessCollapsed, setChildlessCollapsed] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [rowHeight, setRowHeight] = useState();

  const toggleCollapse = () => {
    firstChild
      ? setCollapsed(!collapsed)
      : setChildlessCollapsed(!childlessCollapsed);
  };

  useEffect(() => {
    setRowHeight(
      document.getElementById(rowData.name)?.getBoundingClientRect().height - 18
    );
  }, []);

  useEffect(() => {
    if (collapseAll !== "finished") {
      setCollapsed(collapseAll === "collapsed" ? true : false);
      setChildlessCollapsed(
        collapseAll === "collapsed" || collapseAll === "expanded" ? true : false
      );
      setCollapseAll("finished");
    }
  }, [collapseAll]);

  return (
    <>
      <Box
        className={`row ${
          firstChild ? "" : childCollapsed ? "collapsed" : "expanded"
        }`}
        sx={{
          height: firstChild ? "auto" : childCollapsed ? 0 : rowHeight,
          paddingLeft: firstChild ? "10px" : "20px",
          backgroundColor: firstChild ? "#1976d2" : "rgb(127 148 169)",
          fontSize: firstChild ? "18px" : "16px",
          opacity: 1,
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          transition: "all 0.2s ease",
          "&:hover": {
            boxShadow: "none",
          },
        }}
        id={rowData.name}
        key={rowData.name}
        onClick={toggleCollapse}
      >
        <CollapseIcon
          collapsed={firstChild ? collapsed : childlessCollapsed}
          firstChild={firstChild}
        />
        <p style={{ maxWidth: "calc(100% - 80px)", margin: 0 }}>
          {rowData.name}
        </p>
      </Box>
      {rowData?.children?.map((child, i) =>
        child.children ? (
          <TableRow
            rowData={child}
            key={child.name}
            collapseAll={collapseAll}
            childCollapsed={collapsed}
            setCollapseAll={setCollapseAll}
          />
        ) : (
          <ChildlessRow
            rowData={child}
            key={child.name}
            rowCollapsed={childlessCollapsed || childCollapsed}
          />
        )
      )}
    </>
  );
};
