import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { managePlanGeneration } from "../../reducers/treeMapSlice";
import { IsMobile } from "../../utils/deviceType";
import * as d3 from "d3";
import { getAnalytics, logEvent } from "firebase/analytics";

const isSafariBrowser =
  navigator.userAgent.includes("Safari") &&
  !navigator.userAgent.includes("Chrome");

const DecisionTreeVisualization = (props) => {
  const svgRef = useRef();
  const [leafNodes, setLeafNodes] = useState([]);
  const [nodeExpanded, setNodeExpanded] = useState(false);
  const dispatch = useDispatch();
  const isMobile = IsMobile();
  const analytics = getAnalytics();
  const treeData = props.treeData;
  const selectedTreePlannedNodeNames = useSelector(
    (state) => state.treeMap.selectedTreePlannedNodeNames
  );
  const selectedTreeNode = useSelector(
    (state) => state.treeMap.selectedTreeNode
  );
  const userData = useSelector((state) => state.treeMap.userData);
  const treeLoading = props.treeLoading;
  const customColors = ["#002244", "#005BB5", "#A50021", "#008BBD"];
  const colors = d3.scaleOrdinal(customColors);

  const assignColor = (node, color) => {
    node.color = color;
    if (node.children) {
      node.children.forEach((child) => assignColor(child, color));
    }
  };

  const handleNodeClick = (nodeData) => {
    logEvent(analytics, `Plan it Clicked!`, {
      user: userData._id,
    });
    dispatch(managePlanGeneration(nodeData.data.name));
  };

  const Spakles = (isPlan, selected) =>
    `<svg width=${isPlan ? "13" : "16"} height=${
      isPlan ? "13" : "16"
    } viewBox="0 0 14 14" fill=${
      selected ? "#1D77B7" : "none"
    } style="margin-left: 5px;"
      >
          <path d="M8.88196 0.599976L10.1021 3.89745L13.3996 5.11762L10.1021 6.3378L8.88196 9.63527L7.66179 6.3378L4.36432 5.11762L7.66179 3.89745L8.88196 0.599976Z" stroke=${
            selected ? "#1D77B7" : "#585858"
          } stroke-width="1" stroke-linejoin="round" />
          <path d="M3.2349 8.12939L4.30079 9.6988L5.8702 10.7647L4.30079 11.8306L3.2349 13.4L2.16902 11.8306L0.599609 10.7647L2.16902 9.6988L3.2349 8.12939Z" stroke=${
            selected ? "#1D77B7" : "#585858"
          } stroke-width="1" stroke-linejoin="round" />
        </svg>`;

  const showImgOver = (d) => {
    document.getElementById("planSpan")?.remove();
    document.getElementById(`sparkle${d.data.name}`) &&
      document.getElementById(`sparkle${d.data.name}`).remove();

    let svgContainer = document.createElement("span");
    svgContainer.innerHTML = Spakles(
      "",
      selectedTreeNode && selectedTreeNode === d.data.name
    );

    if (!d._children) {
      let planImgSpan = document.createElement("span");
      planImgSpan.id = `sparkle${d.data.name}`;
      planImgSpan.style.cssText = `margin-right: 40px;`;

      if (d.data.name) {
        let imgContainer = document.createElement("span");
        imgContainer.appendChild(svgContainer.firstChild);
        planImgSpan.appendChild(imgContainer);
      }
      document.getElementById(d.id)?.appendChild(planImgSpan);
    }
  };

  const handleMouseOver = (d) => {
    let svgContainer = document.createElement("span");
    svgContainer.innerHTML = Spakles("plan");
    if (!d._children && !document.getElementById(`sparkle${d.data.name}`)) {
      let planSpan = document.createElement("span");
      planSpan.innerHTML = "Plan";
      planSpan.id = "planSpan";
      planSpan.style.cssText = `
                            background-color: #EEEEEE;
                            transition: background-color 0.15s ease-in-out;
                            color: #585858;
                            padding: 0.35rem 0.6rem;
                            width: fit-content;
                            height: fit-content;
                            border-radius: 8px;
                            margin-right: 3px;
                            display: flex;
                            font-size: 12px;
                            cursor: pointer;
                        `;
      planSpan.addEventListener("mouseenter", () => {
        planSpan.style.backgroundColor = "#1D77B7";
        planSpan.style.color = "white";
        planSpan
          .querySelector("svg")
          .querySelectorAll("path")
          .forEach((path) => {
            path.style.stroke = "#FFFFFF";
          });
      });

      planSpan.addEventListener("mouseleave", () => {
        planSpan.style.backgroundColor = "#EEEEEE";
        planSpan.style.color = "#585858";
        planSpan
          .querySelector("svg")
          .querySelectorAll("path")
          .forEach((path) => {
            path.style.stroke = "#585858";
          });
      });

      planSpan.appendChild(svgContainer.firstChild);
      planSpan.addEventListener("click", () => handleNodeClick(d));
      document.getElementById(d.id).appendChild(planSpan);
    }
  };

  const handleMouseOut = (d) => {
    if (!d._children) {
      document.getElementById("planSpan")?.remove();
    }
  };

  const calculateDelay = (d) => {
    let delay = (d.x - d.y + 2200) / 1.02;
    while (d.parent) {
      delay += d.depth * 100;
      d = d.parent;
    }
    return delay;
  };

  const calculateMaxNodeHeight = (localLeafNodess) => {
    const longestNameLength = localLeafNodess.reduce((maxLength, obj) => {
      const nameLength = obj.data.name.length;
      return nameLength > maxLength ? nameLength : maxLength;
    }, 0);
    return longestNameLength * 0.9;
  };

  const getLeafNodes = (node) => {
    if (!node.children) {
      return [node];
    }
    return node.children.flatMap(getLeafNodes);
  };

  useEffect(() => {
    if (treeData) {
      // Clearing D3 chart if new tree Data is created
      d3.select(svgRef.current).selectAll("*").remove();
      const data = treeData;
      const width = 950;
      const marginTop = 30;
      const marginBottom = 90;

      const root = d3.hierarchy(data);
      const localLeafNodess = getLeafNodes(root);
      setLeafNodes(localLeafNodess);

      const dx = calculateMaxNodeHeight(localLeafNodess);
      const dy = width / (root.height + 1.6);

      // Define the tree layout and the shape for links.
      const tree = d3.tree().nodeSize([dx, dy]);
      const diagonal = d3
        .linkHorizontal()
        .x((d) => {
          return d.y;
        })
        .y((d) => d.x);

      root.children.forEach((child, i) => assignColor(child, colors(i + 1)));
      // Create the SVG container, a layer for the links and a layer for the nodes.
      const svg = d3
        .select(svgRef.current)
        .attr("id", "currentTree")
        .attr("width", width)
        .attr("height", 0)
        .attr("viewBox", [0, 0, width, 0])
        .attr(
          "style",
          "max-width: 100%; height: auto; font: 10px sans-serif; user-select: none; background: white"
        );

      const gLink = svg
        .append("g")
        .attr("fill", "none")
        .attr("stroke-opacity", 0.7)
        .attr("stroke-width", 0.5);

      const gNode = svg.append("g");

      function update(source) {
        const isRootNode = source == root;
        const duration = isRootNode ? 1000 : 500;
        const nodes = root.descendants().reverse();
        const links = root.links();

        tree(root);
        let left = root;
        let right = root;
        root.eachBefore((node) => {
          if (node.x < left.x) left = node;
          if (node.x > right.x) right = node;
        });
        const height = right.x - left.x + marginTop + marginBottom;

        const transition = svg
          .transition()
          .duration(duration)
          .attr("height", height)
          .attr("viewBox", [-dy / 3 - 60, left.x - marginTop, width, height]);

        const node = gNode.selectAll("g").data(nodes, (d) => d.id);

        const nodeEnter = node
          .enter()
          .append("g")
          .attr("transform", (d) => `translate(${source.y0},${source.x0})`);

        nodeEnter
          .append("circle")
          .attr("r", 2.5)
          .attr("fill", (d) =>
            d._children ? d3.color(d.color) : d3.color(d.color).copy()
          )
          .attr("stroke-width", 10);

        nodeEnter
          .append("foreignObject")
          .attr("x", (d) =>
            d._children ? (isMobile ? -dy / 2 - 35 : -dy / 2 - 25) : 6
          )
          .attr("y", "-0.75em")
          .attr("width", dy / 1.1)
          .attr("height", dx)
          .append("xhtml:div")
          .attr("id", (d) => d.id)
          .on("mouseenter", (d) => handleMouseOver(d.target.__data__))
          .on("mouseleave", (d) => handleMouseOut(d.target.__data__))
          .attr(
            "style",
            (d) =>
              `opacity: ${isSafariBrowser ? 1 : 0};
               visibility: hidden;
               display: ${d._children ? "block" : "flex"};
               height: ${d._children ? "30px" : "initial"};
               justify-content: space-between;`
          )
          .filter((d) => d._children)
          .append("xhtml:div")
          .attr(
            "style",
            `-webkit-text-stroke: 8px white;
             width: ${isMobile ? "70%" : "65%"};
             font-size: 12px;
             direction: rtl;
             height: 100%;
            `
          )
          .text((d) => d.data.name);

        nodeEnter
          .select("foreignObject div")
          .append("xhtml:div")
          .attr("class", (d) => {
            if (localLeafNodess[0] == d) {
              return "childlessNode";
            }
          })
          .on("click", (_, d) => {
            if (!d._children) {
              handleNodeClick(d);
            } else {
              if (d.children) {
                d.children = null;
              } else {
                setNodeExpanded(true);
                d.children = d._children;
              }
              update(d);
            }
          })
          .text((d) => d.data.name)
          .attr(
            "style",
            (d) => `
               width: ${isMobile ? "70%" : "65%"};
               height: 100%;
               overflow: visible;
               margin-top: ${d._children ? "-30px" : 0};
               color: ${d.color};
               direction: ${d._children ? "rtl" : "ltr"};
               cursor: pointer;
               font-size: ${
                 d._children
                   ? "12px"
                   : d?.data?.name?.length > 120
                     ? "10px"
                     : "11px"
               };
              `
          );

        nodeEnter
          .select("foreignObject div")
          .transition()
          .delay((d) => (isRootNode ? calculateDelay(d, height) : 0))
          .duration(duration)
          .style("visibility", "visible")
          .style("opacity", 1);

        node
          .merge(nodeEnter)
          .transition(transition)
          .ease(d3.easeCubicInOut)
          .attr("transform", (d) => `translate(${d.y},${d.x})`)
          .attr("fill-opacity", 1)
          .attr("stroke-opacity", 1);

        node
          .exit()
          .transition(transition)
          .ease(d3.easeCubicInOut)
          .remove()
          .attr("transform", (d) => `translate(${source.y},${source.x})`)
          .attr("fill-opacity", 0)
          .attr("stroke-opacity", 0);

        const link = gLink
          .selectAll("path.link")
          .data(links, (d) => d.target.id);

        const linkEnter = link
          .enter()
          .append("path")
          .attr("class", "link")
          .attr("d", (d) => {
            const o = { x: source.x0, y: source.y0 };
            return diagonal({ source: o, target: o });
          })
          .attr("transform", (d) => `translate(${source.y},${source.x})`)
          .attr("stroke", (d) => d.target.color);

        link
          .merge(linkEnter)
          .transition(transition)
          .ease(d3.easeCubicInOut)
          .duration(duration)
          .attr("d", diagonal)
          .attr("transform", (d) => {
            if (d.y && d.x) {
              return `translate(${d.source.y},${d.source.x})`;
            }
          });
        // Include the transition for transform as well;

        link
          .exit()
          .transition(transition)
          .ease(d3.easeCubicInOut)
          .remove()
          .attr("d", (d) => {
            const o = { x: source.x, y: source.y };
            return diagonal({ source: o, target: o });
          });

        root.eachBefore((d) => {
          d.x0 = d.x;
          d.y0 = d.y;
        });
      }

      root.x0 = dy / 2;
      root.y0 = 0;
      root.descendants().forEach((d, i) => {
        d.id = i;
        d._children = d.children;
        // Add Below line to show nodes till level 1
        // if (d.depth && d.data.name.length !== 7) d.children = null;
      });
      // if (!processedRef.current) {
      update(root);
    }
  }, [treeData, treeLoading]);

  useEffect(() => {
    leafNodes.forEach(
      (d) =>
        selectedTreePlannedNodeNames.includes(d.data.name) && showImgOver(d)
    );
    setNodeExpanded(false);
  }, [selectedTreePlannedNodeNames, selectedTreeNode, leafNodes, nodeExpanded]);

  return <svg ref={svgRef} width="100%" height="100%" />;
};

export default DecisionTreeVisualization;
