import React, { useState } from "react";
import { TableRow } from "./TableRow";
import { Tooltip, IconButton } from "@mui/material";
import { ReactComponent as CollapseIcon } from "../../assets/images/icons/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../assets/images/icons/expand.svg";
import { IsMobile } from "../../utils/deviceType";

const IndentedList = ({ data }) => {
  const [collapseAll, setCollapseAll] = useState("finished");
  const [lastCollapseState, setLastCollapseState] = useState("fully_expanded");
  const isMobile = IsMobile();

  return (
    <div style={{ width: "100%", padding: "7px", boxSizing: "border-box" }}>
      <div className="indentedListHeader">
        <div />
        <div
          style={{
            backgroundColor: "rgba(239, 239, 239, 1)",
            padding: 4,
            borderRadius: 4,
          }}
        >
          <Tooltip title="Expand All" arrow>
            <IconButton
              onClick={() => {
                setCollapseAll("fully_expanded");
                setLastCollapseState("fully_expanded");
              }}
              sx={{
                padding: "0px",
                margin: "0px 5px",
                backgroundColor: "transparent",
              }}
            >
              <ExpandIcon
                width={isMobile ? 18 : 14}
                height={isMobile ? 18 : 14}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Collapse" arrow>
            <IconButton
              onClick={() => {
                if (lastCollapseState === "fully_expanded") {
                  setCollapseAll("expanded");
                  setLastCollapseState("expanded");
                } else {
                  setCollapseAll("collapsed");
                  setLastCollapseState("collapsed");
                }
              }}
              sx={{
                padding: "0px",
                margin: "0px 5px",
                backgroundColor: "transparent",
              }}
            >
              <CollapseIcon
                width={isMobile ? 18 : 14}
                height={isMobile ? 18 : 14}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div id="currentList" style={{ width: "100%", paddingBottom: 20 }}>
        {data?.map((row, index) => (
          <TableRow
            rowData={row}
            firstChild
            key={row.name + "-" + index}
            collapseAll={collapseAll}
            setCollapseAll={setCollapseAll}
          />
        ))}
      </div>
    </div>
  );
};

export default IndentedList;
